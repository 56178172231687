body {
  font-family: 'Recursive', sans-serif;
}

button {
  background: linear-gradient(93.06deg, rgb(255, 0, 199) 2.66%, rgb(255, 159, 251) 98.99%);
  border: none;
  color: rgb(255, 255, 255);
  transition: all 250ms ease 0s;
  padding: 16px 0px;
  border-radius: 24px;
}

button:hover{
  box-shadow: rgb(255, 0, 199) 0px 0px 16px 0px;
}

.gradient {
  position: absolute;
  display: flex;
  top: 0px;
  bottom: 0px;
  width: 100%;
  min-height: 550px;
  background: rgb(255, 255, 255);
}

.offer {
  padding: 20px;
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  /* Center the offer div when its width is less than the max-width */
  margin-right: auto;
  /* Center the offer div when its width is less than the max-width */
  background: rgb(255, 255, 255);
  position: relative;
  z-index: 1;
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.2s;
  border: 1px transparent;
}

.offer:not(.clicked){
  box-shadow: 0 -70px 100px rgba(222, 61, 142, 0.684);
}

.offer.clicked {
  border: 1px solid rgba(252, 114, 255, 0.5);
}

.offer:not(.clicked)::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  /* this determines the height of the gradient effect. You can adjust this value as needed. */
  background: linear-gradient(to bottom, rgba(252, 252, 252, 0.529) 0%, white 100%);
}

.offer:not(.clicked):hover {
  transform: translateY(-14px);
  border: 1px solid rgba(252, 114, 255, 0.5);
  border-bottom: none;
}

.offer-wrapper {
  position: relative;
  width: 100%;
}

.h1-wrapper {
  padding-top: 20px;
  background: radial-gradient(rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 50px;
  width: 50%;
  /* Styles for positioning */
  position: absolute;
  top: 80%;
  /* Center it vertically */
  left: 50%;

  transform: translate(-50%, -10%);
  /* Adjust the position */
  z-index: 2;
  align-items: center;
}

h1 {
  color: transparent;
  font-size: 64px;
  line-height: 72px;
  font-weight: 535;
  text-align: center;
  margin: 0px 0px 24px;

  background: linear-gradient(0deg, rgb(236, 166, 36) 10%, rgb(255, 87, 218) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* For webkit browsers like Chrome and Safari */
  color: transparent;
}

.offer h2 {
  margin-bottom: 20px;
  color: rgba(34, 34, 34, 0.9);
}

.offer input,
.offer select {
  padding: 10px 15px;
  border-radius: 12px;
  border: 1px solid rgba(252, 114, 255, 0.5);
  width: calc(33.33% - 12px);
  /* Three items with a gap of 6px between each */
  background-color: white;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); */
  transition: all 0.3s ease;
}

.offer input:focus,
.offer select:focus {
  border-color: rgb(252, 114, 255);
  outline: none;
  /* box-shadow: 0 2px 6px rgba(252, 114, 255, 0.2); */
}

.offer div {
  display: flex;
  justify-content: space-between;
  align-items: left;
}

select{
  font-weight: 700;
}
select:focus {
  outline: none;
  box-shadow: rgb(255, 0, 199) 0px 0px 4px 0px;
}
select:hover{ 
  box-shadow: rgb(255, 0, 199) 0px 0px 16px 0px;
}

.table-landing:hover {
  box-shadow: rgb(255, 0, 199) 0px 0px 16px 0px;
}

.input-panel{
  justify-items: center;
  background-color: rgb(249, 249, 249);
  border-radius: 16px;
  color: rgb(125, 125, 125);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 16px;
  position: relative;
  width: 100%;
}

.inner-input-panel {
  min-height: 44px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex: row;
  justify-content: center;
}

.input-label {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 485;
  font-size: 14px;
  color: rgb(125, 125, 125);
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-content {
  display: none;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  /* Position it below the button */
  left: 50%;
  margin-left: -100px;
  /* center it */
  opacity: 0.8;
}

.tooltip:hover .tooltip-content {
  display: block;
}